<template>
  <BaseListPage locale-section="pages.materialSubTypes" route="materialSubType">
    <MaterialSubTypesList />
  </BaseListPage>
</template>

<script>
export default {
  name: "MaterialSubTypesPage",
  components: {
    BaseListPage: () => import("@/components/BaseListPage"),
    MaterialSubTypesList: () => import("./MaterialSubTypesList")
  }
};
</script>
